import type { ICustomer } from '../types/customer.d'
import { LocalStorageKey } from '@/modules/core/enums'
import useAuth from './useAuth'
import useAuthRefreshToken from './useAuthRefreshToken'
import useCustomer from './useCustomer'

export default async () => {
  const accessToken = localStorage.getItem(LocalStorageKey.AccessToken) as string
  const refreshToken = localStorage.getItem(LocalStorageKey.RefreshToken) as string

  const { logout } = useAuth()
  const { loading, customer } = useCustomer()

  try {
    loading.value = true

    const baseURL = useRuntimeConfig().public.api.awsGateway
    const response = await $fetch<ICustomer>(`/v1/customer/users/${customer.value.id}`, {
      baseURL,
      headers: {
        AuthToken: accessToken,
      },
    })

    customer.value = snakeToCamel(response)
  }
  catch (error: any) {
    const { $toast } = useNuxtApp()

    if (error.response.status === 403) {
      if (refreshToken) {
        useAuthRefreshToken()

        return
      }

      $toast.error('Sessão expirada, faça login novamente.')

      logout()

      return
    }

    $toast.error('Falha ao buscar dados do usuário, tente novamente.')
  }
  finally {
    loading.value = false
  }
}
