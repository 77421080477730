import type { ICustomer } from './customer.d'

export enum CustomerError {
  INVALID_CPF = 'E10003',
  TAX_ID_ALREADY_EXISTS = 'E10025',
  EMAIL_ALREADY_EXISTS = 'E10026',
  PHONE_ALREADY_EXISTS = 'E10027',
  USER_NOT_FOUND = 'not_found',
  UNPROCESSABLE_ENTITY = 'unprocessable_entity',
}

export interface CustomerErrorInfo {
  message: string
  formFields: (keyof ICustomer)[]
}

export type CustomerErrorMessageType = {
  [key in CustomerError]: CustomerErrorInfo
}

export const CustomerErrorMessage: CustomerErrorMessageType = {
  [CustomerError.INVALID_CPF]: {
    message: 'CPF do usuário inválido.',
    formFields: ['taxId'],
  },
  [CustomerError.TAX_ID_ALREADY_EXISTS]: {
    message: 'Já existe um usuário cadastrado com este CPF.',
    formFields: ['taxId'],
  },
  [CustomerError.EMAIL_ALREADY_EXISTS]: {
    message: 'Já existe um usuário cadastrado com este e-mail.',
    formFields: ['email'],
  },
  [CustomerError.PHONE_ALREADY_EXISTS]: {
    message: 'Telefone já existente.',
    formFields: ['phone'],
  },
  [CustomerError.USER_NOT_FOUND]: {
    message: 'Usuário não encontrado.',
    formFields: [],
  },
  [CustomerError.UNPROCESSABLE_ENTITY]: {
    message: 'Erro ao cadastrar.',
    formFields: [],
  },
}
