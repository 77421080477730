export enum CHECKOUT_STEPS {
  BUDGET = 'BUDGET',
  PERSONAL_DATA = 'PERSONAL_DATA',
  BILLING_ADDRESS = 'BILLING_ADDRESS',
  MEDICAL_REQUEST = 'MEDICAL_REQUEST',
  PAYMENT = 'PAYMENT',
  FINISH = 'FINISH',
  REALIZED = 'REALIZED',
}

interface IStepData {
  title: string
  percent: number
}

export const CHECKOUT_STEP_DATA = {
  [CHECKOUT_STEPS.BUDGET]: {
    title: 'Resumo do pedido',
    percent: 14,
  } as IStepData,
  [CHECKOUT_STEPS.PERSONAL_DATA]: {
    title: 'Dados pessoais',
    percent: 28,
  } as IStepData,
  [CHECKOUT_STEPS.BILLING_ADDRESS]: {
    title: 'Endereço de cobrança',
    percent: 57,
  } as IStepData,
  [CHECKOUT_STEPS.MEDICAL_REQUEST]: {
    title: 'Pedido Médico',
    percent: 71,
  } as IStepData,
  [CHECKOUT_STEPS.PAYMENT]: {
    title: 'Forma de Pagamento',
    percent: 85,
  } as IStepData,
  [CHECKOUT_STEPS.REALIZED]: {
    title: 'Pedido Realizado',
    percent: 100,
  } as IStepData,
}

export enum CartError {
  CART_NOT_FOUND = 'E21005',
  WITHOUT_PROCEDURES = 'E21007',
  WITHOUT_ADDRESS = 'E21006',
  CART_IS_NOT_ACTIVE = 'E21009',
  PROCEDURE_IS_NOT_ACTIVE = 'E21014',
  FAILED_TO_APPLY_COUPON = 'E21011',
  WITHOUT_USER_NAME = 'E21012',
  FINISH_SALE = 'E21008',
}

export type CartErrorMessageType = { [key in CartError]: string }

export const CartErrorMessage: CartErrorMessageType = {
  [CartError.CART_NOT_FOUND]: 'Orçamento não encontrado.',
  [CartError.WITHOUT_PROCEDURES]: 'Orçamento sem exames.',
  [CartError.WITHOUT_ADDRESS]: 'Orçamento sem endereço.',
  [CartError.CART_IS_NOT_ACTIVE]: 'Orçamento desativado.',
  [CartError.PROCEDURE_IS_NOT_ACTIVE]: 'Há procedimentos desativados em seu orçamento.',
  [CartError.WITHOUT_USER_NAME]: 'Usuário sem nome cadastrado.',
  [CartError.FAILED_TO_APPLY_COUPON]: 'Falha ao aplicar cupom, tente novamente.',
  [CartError.FINISH_SALE]: 'Falha ao finalizar venda, tente novamente.',
}

export enum CheckoutOrderError {
  ORDER_NOT_FOUND = 'E13007',
  ORDER_NOT_ELIGIBLE = 'E13051',
  INSTALLMENT_NUMBER_HIGHER = 'E13018',
  CARD_TOKEN_REQUIRED = 'E13030',
  PAYMENT_GATEWAY = 'E13037',
}

export type CheckoutOrderErrorMessageType = { [key in CheckoutOrderError]: string }

export const CheckoutOrderErrorMessage: CheckoutOrderErrorMessageType = {
  [CheckoutOrderError.ORDER_NOT_FOUND]: 'Pedido não encontrado',
  [CheckoutOrderError.ORDER_NOT_ELIGIBLE]: 'Pedido não elegível para checkout',
  [CheckoutOrderError.INSTALLMENT_NUMBER_HIGHER]: 'Número de parcelas maior que o permitido',
  [CheckoutOrderError.CARD_TOKEN_REQUIRED]: 'Token do cartão é necessário para pagamento com cartão',
  [CheckoutOrderError.PAYMENT_GATEWAY]: 'Erro inesperado no gateway de pagamentos',
}
