import type { ICart } from '@/modules/cart/types/cart.d'
import type { ICustomer } from '@/modules/customer/types/customer.d'
import { LocalStorageKey } from '@/modules/core/enums'

import { EventName } from '@/modules/trevo/types/enum'
import { isJWT } from '@/validators/helpers'

import { jwtDecode } from 'jwt-decode'
import useCart from './useCart'

export default async () => {
  const { cart, loading, clear } = useCart()

  try {
    loading.value = true

    const accessToken = localStorage.getItem(LocalStorageKey.AccessToken) as string

    const { token: tokenFromQuery } = useRoute().query
    const token = tokenFromQuery as string || accessToken

    if (!isJWT(token))
      return

    const userFromToken: ICustomer = jwtDecode(token)

    if (!userFromToken || !userFromToken.id || userFromToken.exp * 1000 < Date.now())
      return

    const baseURL = useRuntimeConfig().public.api.awsGateway

    const carts = await $fetch<ICart[]>(`/v1/recommendations/users/${userFromToken.id}/cart`, {
      baseURL,
      headers: {
        AuthToken: token,
      },
    })

    cart.value = snakeToCamel(carts[0])

    useCartUpdateInstallments()
  }
  catch (error: any) {
    const { $toast } = useNuxtApp()

    if (error.response.status === 404) {
      if (useRoute().name?.toString().includes('checkout')) {
        useErrorMessage().value = 'Orçamento expirado, busque novos exames.'

        track(EventName.CartExpired)

        clear()

        return navigateTo('/perfil/historico-de-exames')
      }

      return null
    }

    $toast.error('Falha ao buscar dados de seu orçamento, tente novamente.')
  }
  finally {
    loading.value = false
  }
}
