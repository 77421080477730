import { LocalStorageKey, TestABGroups } from '@/modules/core/enums'
import useCustomer from '@/modules/customer/composables/useCustomer'
import { testPages, testWeights } from '@/modules/trevo/constants'

function _determineTestGroup(): TestABGroups {
  const testGroups = Object.keys(testWeights) as TestABGroups[]
  const totalWeight = Object.values(testWeights).reduce((acc, weight) => acc + weight, 0)

  let random = Math.random() * totalWeight

  for (const group of testGroups) {
    const weight = testWeights[group]
    if (random < weight) {
      return group
    }
    random -= weight
  }

  return TestABGroups.CONTROL_AB_TEST
}

export default function (): string {
  if (!import.meta.client)
    return ''

  const { customer } = useCustomer()

  const isTestPage = testPages.includes(useRoute().name)
  let abTest = localStorage.getItem(LocalStorageKey.TEST_AB) as TestABGroups
  const abTestUser = customer.value?.abGroup || null as TestABGroups

  if (!abTest) {
    if (abTestUser) {
      abTest = abTestUser
    }
    else if (!isTestPage) {
      abTest = TestABGroups.IGNORE_AB_TEST
    }
    else {
      abTest = _determineTestGroup()
    }
  }

  if (!Object.values(TestABGroups).includes(abTest) || (!!abTestUser && abTest !== abTestUser)) {
    abTest = TestABGroups.RESET_AB_TEST
  }

  localStorage.setItem(LocalStorageKey.TEST_AB, abTest)
  return abTest
}
