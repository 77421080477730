import type { ICustomer } from '../types/customer.d'

import { LocalStorageKey } from '@/modules/core/enums'
import { CustomerErrorMessage } from '@/modules/customer/types/customer.enum'
import { EventName } from '@/modules/trevo/types/enum'

import useAuth from './useAuth'

const genderOptions = [
  {
    text: 'Feminino',
    value: 'feminino',
  },
  {
    text: 'Feminino transgênero',
    value: 'feminino_trans',
  },
  {
    text: 'Masculino',
    value: 'masculino',
  },
  {
    text: 'Masculino transgênero',
    value: 'masculino_trans',
  },
  {
    text: 'Não binário',
    value: 'nao_binario',
  },
  {
    text: 'Outro',
    value: 'outro',
  },
]

function useCustomer() {
  const customer = useState<ICustomer>('Customer', () => ({} as ICustomer))
  const loading = useState<boolean>('CustomerLoading', () => false)
  const errors = useState<Record<string, string | undefined>>('CustomerErrors', () => ({}))

  return {
    customer,
    loading,
    errors,
    genderOptions,
  }
}

export default useCustomer

export function useCustomerGlobally() {
  const { customer, loading, errors } = useCustomer()
  const { showSignUpModal, showTokenModal, signInModalWithoutGoogle } = useAuth()

  watch(customer, async (newCustomer, oldCustomer) => {
    if (newCustomer.id)
      await setUserIdOnTracking()

    if (newCustomer.picture) {
      signInModalWithoutGoogle.value = true

      return
    }

    if (!newCustomer.id)
      return

    if (newCustomer.exp)
      return

    if (
      !customer.value.name
      || !customer.value.email
      || !customer.value.birthDate
      || !customer.value.gender
    ) {
      showTokenModal.value = false
      showSignUpModal.value = true

      return
    }

    if (!oldCustomer.id) {
      return
    }

    if (oldCustomer.exp) {
      showTokenModal.value = false
      return
    }

    try {
      loading.value = true
      errors.value = {}

      const baseURL = useRuntimeConfig().public.api.awsGateway

      const accessToken = localStorage.getItem(LocalStorageKey.AccessToken) as string

      await $fetch(`/v1/customer/users/${customer.value.id}`, {
        baseURL,
        method: 'PATCH',
        body: JSON.stringify(camelToSnake(customer.value)),
        headers: {
          Authtoken: accessToken,
        },
      })

      track(EventName.UpdateUser)

      showSignUpModal.value = false
    }
    catch (error: any) {
      const { $toast } = useNuxtApp()

      handleApiErrors(
        error,
        errors,
        CustomerErrorMessage,
        'Falha ao salvar dados do usuário. Verifique os dados e tente novamente.',
      )

      if (errors.value.form) {
        $toast.error(errors.value?.form || '')
      }
      else {
        $toast.error('Falha ao salvar dados do usuário. Verifique os dados e tente novamente.')
      }
    }
    finally {
      loading.value = false
    }
  }, { deep: true })
}
