import type { EventName } from '@/modules/trevo/types/enum'
import useCustomer from '@/modules/customer/composables/useCustomer'
import * as Sentry from '@sentry/vue'
import mixpanel from 'mixpanel-browser'

interface IEventInfo {
  [key: string]: any
}
function track(name: EventName, info: IEventInfo = {} as IEventInfo): void {
  try {
    if (!useTrackInitialized().value) {
      initializeTracking().then(() => {
        track(name, info)
      })

      return
    }

    info.price = useCart().bundleSelected.value?.bundlePrice
    info.userId = useCustomer().customer.value?.id
    info.page = useRoute().name as string
    info.abTest = abTest()
    info.userAgent = navigator?.userAgent

    useTrackEvent(name, {
      deviceId: useTrackMixpanelDistinctId().value,
      ...info,
    })

    mixpanel.track(name, {
      ...info,
      sessionId: useTrackGoogleSessionId().value,
      clientId: useTrackGoogleClientId().value,
    })
  }
  catch (error) {
    console.error('Error tracking event:', error)
    Sentry.captureException(error)
  }
}

export default track
