export const appHead = {"meta":[{"name":"viewport","content":"width=device-width,initial-scale=1,viewport-fit=cover"},{"charset":"utf-8"},{"name":"theme-color","content":"#ffffff"}],"link":[{"rel":"preconnect","href":"https://web-cdn.saudetrevo.com.br/"},{"rel":"preconnect","href":"https://unpkg.com/"},{"rel":"preconnect","href":"https://static.hotjar.com/"},{"rel":"preconnect","href":"https://www.googletagmanager.com/"},{"rel":"icon","type":"image/x-icon","href":"https://web-cdn.saudetrevo.com.br/trevo/favicon.svg"}],"style":[],"script":[{"innerHTML":"(function(h,o,t,j,a,r){\n          h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};\n          h._hjSettings={hjid:3361078,hjsv:6};\n          a=o.getElementsByTagName('head')[0];\n          r=o.createElement('script');r.async=1;\n          r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;\n          a.appendChild(r);\n        })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');","async":true,"tagPosition":"bodyClose"},{"src":"https://d335luupugsy2.cloudfront.net/js/loader-scripts/78787b65-290f-4390-873d-a12ed12b6048-loader.js","async":true,"tagPosition":"bodyClose"}],"noscript":[],"htmlAttrs":{"lang":"pt-BR","translate":"no"},"bodyAttrs":{"class":"relative"},"charset":"utf-8","viewport":"width=device-width,initial-scale=1,viewport-fit=cover"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const renderJsonPayloads = true

export const componentIslands = true

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink","prefetch":true,"prefetchOn":{"visibility":true}}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000

export const multiApp = false

export const chunkErrorEvent = "vite:preloadError"