export enum OrderStatus {
  Pending = 'Pending',
  Waiting_For_Payment = 'Waiting_For_Payment',
  Denied = 'Denied',
  Canceled = 'Canceled',
  Approved = 'Approved',
  Ready = 'Ready',
  Finished = 'Finished',
  Refunded = 'Refunded',
  Expired = 'Expired',
  Pending_But_Authorized = 'Pending_But_Authorized',
  Waiting_For_Refund = 'Waiting_For_Refund',
  Waiting_For_Results = 'Waiting_For_Results',
  Schedule_Confirmation = 'Schedule_Confirmation',
}

export enum LocalStorageKey {
  AccessToken = 'AccessToken',
  RefreshToken = 'RefreshToken',
  TEST_AB = 'testAB',
  PRESCRIPTION_HASHES = 'prescriptionHashes',
  GCLID = 'gclid',
  GBRAID = 'gbraid',
  WBRAID = 'wbraid',
}

export enum SessionStorageKey {
  OrderCreated = 'order_created',
}

export enum TestABGroups {
  CONTROL_AB_TEST = 'controlABTest',
  RESET_AB_TEST = 'resetABTest',
  HIDE_WHATSAPP = 'hideWhatsapp',
  IGNORE_AB_TEST = 'ignoreABTest',
  SHOW_CATEGORIES = 'showCategories',
  SHOW_SLUG_WITH_TAB_LIST = 'showSlugWithTabList',
}

export enum UserIdentifiersEvent {
  CHAT_INTERACTION = 'chat_interaction',
  LOGIN = 'login',
  CHECKOUT = 'checkout',
}
