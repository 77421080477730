import type { Ref } from 'vue'

interface FormError {
  message: string
  formFields: string[]
}

interface ErrorMapping {
  [errorCode: string]: FormError
}

export default (
  error: any,
  errorsRef: Ref<Record<string, string | undefined>>,
  errorMapping: ErrorMapping,
  defaultErrorMessage: string = 'Ocorreu um erro. Por favor, tente novamente.',
) => {
  errorsRef.value = {}

  if (error.response) {
    const errorCode = error.response._data?.error_code
    if (errorCode && errorMapping[errorCode]) {
      const errorInfo = errorMapping[errorCode]
      errorInfo.formFields.forEach((field) => {
        errorsRef.value[field] = errorInfo.message
      })
    }
    else {
      errorsRef.value.form = defaultErrorMessage
    }
  }
  else {
    errorsRef.value.form = 'Erro de rede ou o servidor não está respondendo.'
  }
}
