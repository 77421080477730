import { TestABGroups } from '@/modules/core/enums'

export const cards: any = {
  Mastercard: 'https://web-cdn.saudetrevo.com.br/icons/mastercard.svg',
  Amex: 'https://web-cdn.saudetrevo.com.br/icons/amex.svg',
  Dinners: 'https://web-cdn.saudetrevo.com.br/icons/dinners.svg',
  Elo: 'https://web-cdn.saudetrevo.com.br/icons/elo.svg',
  Hiper: 'https://web-cdn.saudetrevo.com.br/icons/hiper.svg',
  Visa: 'https://web-cdn.saudetrevo.com.br/icons/hiper.svg',
}

export const whatsAppLink
  = 'https://api.whatsapp.com/send?phone=5511957756341&text=Oi%20Sa%C3%BAde%20Trevo.%20Gostaria%20de%20realizar%20um%20or%C3%A7amento%20de%20exame%20com%20voc%C3%AAs.%20Podem%20me%20ajudar?'

export const humanBodyIllustrations = {
  female: 'https://web-cdn.saudetrevo.com.br/illustrations/female-body.svg',
  male: 'https://web-cdn.saudetrevo.com.br/illustrations/male-body.svg',
}

export const abTests: TestABGroups[] = [
  TestABGroups.HIDE_WHATSAPP,
  TestABGroups.SHOW_CATEGORIES,
  TestABGroups.SHOW_SLUG_WITH_TAB_LIST,
]

export const testWeights = {
  [TestABGroups.HIDE_WHATSAPP]: 0.1,
  [TestABGroups.SHOW_CATEGORIES]: 0.1,
  [TestABGroups.SHOW_SLUG_WITH_TAB_LIST]: 0.3,
  [TestABGroups.CONTROL_AB_TEST]: 0.5,
}

export const testPages = [
  'index',
  'location',
  'location-exames-slug',
  'location-exames',
]
